
import Tabs from 'react-responsive-tabs'; //tabpage
import 'react-responsive-tabs/styles.css';//tabpage
import TaiSui from "../../component/Horosope/TaiSui"
import UseGod from "../../component/Horosope/UseGod"
import styles from "./index.module.css"
import { useState } from "react"

export default function Accessment(props) {

    const [useGod1, setUseGod1] = useState("");
    const [useGod2, setUseGod2] = useState("");
    const [taiSuiQueryTime, setTaiSuiQueryTime] = useState(0);

    const tabDefinition = [
        { name: '太歲', tabContent: () => getTaiSuiUI() },
    ];


    const comeSoon = () => {
        return <div>快將推出，敬請期待</div>
    }


    const getTaiSuiUI = () => {
        return (
            <>
                <UseGod
                    useGod1={useGod1} setUseGod1={setUseGod1}
                    useGod2={useGod2} setUseGod2={setUseGod2}
                    description='輸入用神可增加太歲分析結果'
                    onClick={() => { setTaiSuiQueryTime(Date.now()) }} />


                <TaiSui
                    useGod1={useGod1} useGod2={useGod2}
                    taiSuiQueryTime={taiSuiQueryTime} inputDate={props.inputDate}
                    isMale={props.isMale}
                    isAnalysisReady={true} //if analysis is ready, start getting TaiSui
                />
            </>
        )
    }


    function getTabs() {
        return tabDefinition.map((definition, index) => ({
            title: definition.name,
            getContent: () => definition.tabContent(),
            /* Optional parameters */
            key: index,
            tabClassName: 'tab',
            panelClassName: 'panel',
            transform: false
        }));
    }

    return (
        <>
            {/* tab page for date input */}
            <Tabs items={getTabs()} transform={false} />
        </>
    )
}