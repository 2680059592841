import React, { useState, useEffect } from "react";
import FiveElementCell from "../../FiveElementCell";
import styles from './index.module.css'
import { get } from '../../../axios'
import { compareBirthday } from "../../../Helper/DateTimeHelper";
import { globalData } from "../../../Helper/global";

export default function FiveElementScore(props) {


  const [leftLibra, setLeftLibra] = useState("")
  const [rightLibra, setRightLibra] = useState("")
  const [wood, setWood] = useState("")
  const [fire, setFire] = useState("")
  const [earth, setEarth] = useState("")
  const [water, setWater] = useState("")
  const [gold, setGold] = useState("")

  const setDisplayToStateFromString = (str, value) => {
    if (str === "木") setWood(value);
    else if (str === "火") setFire(value);
    else if (str === "土") setEarth(value);
    else if (str === "金") setGold(value);
    else if (str === "水") setWater(value);
  }

  //if props update, query server and set score
  useEffect(() => {
    if (props.inputDate) {
      getFourPillarScore(props.inputDate, props.isMale)
    }
  }, [props.inputDate, props.isMale])


  const getFourPillarScore = (inputDate, isMale) => {

    //clear result before making request
    setLeftLibra("")
    setRightLibra("")
    setWood("")
    setFire("")
    setEarth("")
    setWater("")
    setGold("")

    get(`api/birthday/fourpillarscore/${inputDate}/ismale/${isMale}?t=${Date.now()}`).then(res => {
      if (res.data.success) {
        if (compareBirthday(res.data.data.birthday, globalData.inputDate)) {
          var scoreObj = res.data.data.score

          //loop all score
          for (const key in scoreObj) {
            if (Object.hasOwnProperty.call(scoreObj, key)) {
              const score = scoreObj[key];
              const tenGod = res.data.data.relation[key]
              const displayValue = key + "(" + tenGod + "): " + score;
              setDisplayToStateFromString(key, displayValue)
            }
          }

          //set libra
          setLeftLibra(res.data.data.libra.left)
          setRightLibra(res.data.data.libra.right)
        } else {

          console.log("fourpillarscore birthday " + res.data.data.birthday + " is not up to date.")

        }
      }
    })
  }




  return (
    <>
      {
        props.inputDate && wood && fire && earth && gold && water && leftLibra && rightLibra?(
        <div>
          <div className={styles.gridContainer}>
            <FiveElementCell className={styles['grid-item']} element="木" text={wood} />
            <FiveElementCell className={styles['grid-item']} element="火" text={fire} />
            <FiveElementCell className={styles['grid-item']} element="土" text={earth} />
            <FiveElementCell className={styles['grid-item']} element="金" text={gold} />
            <FiveElementCell className={styles['grid-item']} element="水" text={water} />
          </div>
          <div className={styles.gridContainer}>
            {(leftLibra !== undefined && leftLibra !== "") && <div>印比: {leftLibra}</div>}
            {(rightLibra !== undefined && rightLibra !== "") && <div>官財傷: {rightLibra}</div>}
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
          </div>
        </div>
        ):(<props.LoadingSpinner height="50px" />)
      }
    </>
  );
}