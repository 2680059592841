import React, { useEffect, useState, useRef } from "react";
import styles from "./index.module.css"
import EightLetters from "../../component/EightLetters";
import YearFortune100 from "../../component/YearFortune100";
import MsonSelectorTab from "../../component/MsonSelectorTab";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function Advanced(props) {

    const [showYearFortune, setShowYearFortune] = useState(false);

    return (<div className={styles.container}>
        {/* <VToolbar extraInfoMask={extraInfoMask} setExtraInfoMask={setExtraInfoMask} /> */}
        <EightLetters
            inputDate={props.inputDate}
            isMale={props.isMale}
            extraInfoMask={props.extraInfoMask}
            selectedYear10={props.selectedYear10}
            selected10YearRange={props.selected10YearRange}
            yearfortune100Result={props.yearfortune100Result}
            selectedYear={props.selectedYear}
            selectedMonth={props.selectedMonth}
            eightLettersResult={props.eightLettersResult}
            //  setEightLettersResult={props.setEightLettersResult}
            selectedLetterIndex={props.selectedLetterIndex}
            setSelectedLetterIndex={props.setSelectedLetterIndex}
            selectedLetter={props.selectedLetter}
            setSelectedLetter={props.setSelectedLetter}
            year10Ready={props.year10Ready}
            selectedYear10IndexNumber={props.selectedYear10IndexNumber}
            addressSchema={props.addressSchema}
            // to indicate wither deviated letter are ready
            isDLetterReady={props.isDLetterReady}
            setIsDLetterReady={props.setIsDLetterReady}
            // for mson selector
            // share from eightLetter to horoscope tab page
            msonIdPartyShowList={props.msonIdPartyShowList}
            setMsonIdPartyShowList={props.setMsonIdPartyShowList}
            letterAddressAllDateObj={props.letterAddressAllDateObj}
            setLetterAddressAllDateObj={props.setLetterAddressAllDateObj}

            //for advanced only
            shouldDrawDLetter={true} //not draw at simple mode

            //analysis result from index page
            analysisResult={props.analysisResult}
            setAnalysisResult={props.setAnalysisResult}
            setIsAnalysisReady={props.setIsAnalysisReady}
            getAnalysisOriginal={props.getAnalysisOriginal}

        />


        <Accordion className={styles.accordion}
            onChange={(event, expanded) => setShowYearFortune(expanded)}

            sx={{ 
                '&.MuiAccordion-root': {
                    backgroundColor: '#eeeeee'
                }
            }}
        >
            <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                className={styles.accordionSummary}
            >
                <>{showYearFortune ? '隱藏大運流年流月' : '顯示大運流年流月'}</>
            </AccordionSummary>

            <AccordionDetails sx={{ backgroundColor: '#ffffff',   padding: '8px' }}>
                    {showYearFortune && props.inputDate && props.eightLettersResult && (
                        <YearFortune100
                            inputDate={props.inputDate}
                            isMale={props.isMale}
                            yearfortune100Result={props.yearfortune100Result}
                            setSelected10YearRange={props.setSelected10YearRange}
                            setSelectedYear10={props.setSelectedYear10}
                            setSelectedYear10IndexNumber={props.setSelectedYear10IndexNumber}
                            setSelectedYear={props.setSelectedYear}
                            setSelectedMonth={props.setSelectedMonth}
                            setYearfortune100Result={props.setYearfortune100Result}
                            // setYear10Ready={props.setYear10Ready}
                            selectedLetterIndex={props.selectedLetterIndex}
                            setSelectedLetterIndex={props.setSelectedLetterIndex}
                            selectedLetter={props.selectedLetter}
                            setSelectedLetter={props.setSelectedLetter}
                            extraInfoMask={props.extraInfoMask}
                            // to indicate wither deviated letter are ready
                            isDLetterReady={props.isDLetterReady}
                            setIsDLetterReady={props.setIsDLetterReady}
                            // for mson selector
                            // share from eightLetter to horoscope tab page
                            msonIdPartyShowList={props.msonIdPartyShowList}
                            setMsonIdPartyShowList={props.setMsonIdPartyShowList}
                            letterAddressAllDateObj={props.letterAddressAllDateObj}
                            setLetterAddressAllDateObj={props.setLetterAddressAllDateObj}
                        />
                    )}
            </AccordionDetails>
        </Accordion>

        {props.inputDate && props.eightLettersResult ?

            <MsonSelectorTab
                letterAddressAllDateObj={props.letterAddressAllDateObj}
                addressSchema={props.addressSchema}
                // for mson selector
                // share from eightLetter to horoscope tab page
                msonIdPartyShowList={props.msonIdPartyShowList}
                setMsonIdPartyShowList={props.setMsonIdPartyShowList}

                shouldWrapWithDraggable={false}
                showMinimizeButton={false}

                //to hide letter by tab when user selected year10, year, month
                selectedYear10={props.selectedYear10}
                selectedYear={props.selectedYear}
                selectedMonth={props.selectedMonth}

            />
            : <props.LoadingSpinner />}
    </div>
    )

}