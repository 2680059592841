import styles from './index.module.css'
import React, { useEffect, useState } from 'react';
import GenderSelection from "../GenderSelection";
import { get } from '../../axios'
import { globalData } from "../../Helper/global";


export default function EightLettersToWestenDate(props) {
  const skyStemOptions = [
    '甲', '乙', '丙', '丁', '戊', '己', '庚', '辛', '壬', '癸'
  ];

  const earthBranchOptions = [
    "子", "丑", "寅", "卯", "辰", "巳", "午", "未", "申", "酉", "戌", "亥"
  ];


  const [yearSky, setYearSky] = useState('甲');
  const [yearEarth, setYearEarth] = useState('子');
  const [monthSky, setMonthSky] = useState('甲');
  const [monthEarth, setMonthEarth] = useState('子');
  const [daySky, setDaySky] = useState('甲');
  const [dayEarth, setDayEarth] = useState('子');
  const [hourSky, setHourSky] = useState('甲');
  const [hourEarth, setHourEarth] = useState('子');

  const [birthday, setBirthday] = useState('');
  // const [showPopup, setShowPopup] = useState(false);
  const [birthdays, setBirthdays] = useState([]);

  const getBirthday = () => {
    setBirthday('') //clear result before making request

    // /api/birthday/chinesedate/1982-07-31T13%3A05'
    get(`api/birthday/westerndate/year/${yearSky}${yearEarth}/month/${monthSky}${monthEarth}/day/${daySky}${dayEarth}/hour/${hourSky}${hourEarth}?t=${Date.now()}`).then(res => {
      if (res.data.success) {
        //don't need to filter input data here because combox is more difficult to quickly change by users.
        if (res.data.data.length === 1) {
          setBirthday(res.data.data[0])
        } else {
          //show popup  
          setBirthdays(res.data.data)
          // setShowPopup(true)
        }
      }
    }).catch(res => {
      //when western date is not found, clear result
      setBirthday('')
      setBirthdays([])
      // setShowPopup(false)

    })
  }

  useEffect(() => {
    getBirthday()
  }
    , [yearSky, yearEarth, monthSky, monthEarth, daySky, dayEarth, hourSky, hourEarth]
  )

  const confirmBirthday = () => {
    globalData.inputDate = birthday
    props.setInputDate(globalData.inputDate)
  }

  return (
    <>
      <div className={styles.text}>請輸入八字</div>

      <div className={styles['grid-container']}>
        <div className={styles['grid-cell']}>
          <select className={styles['comboBox']} id="cell-1" value={hourSky} onChange={(event) => { setHourSky(event.target.value) }}>
            {skyStemOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className={styles['grid-cell']}>
          <select className={styles['comboBox']} id="cell-2" value={daySky} onChange={(event) => { setDaySky(event.target.value) }}>
            {skyStemOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className={styles['grid-cell']}>
          <select className={styles['comboBox']} id="cell-3" value={monthSky} onChange={(event) => { setMonthSky(event.target.value) }}>
            {skyStemOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className={styles['grid-cell']}>
          <select className={styles['comboBox']} id="cell-4" value={yearSky} onChange={(event) => { setYearSky(event.target.value) }}>
            {skyStemOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className={styles['grid-cell']}>
          <select className={styles['comboBox']} id="cell-5" value={hourEarth} onChange={(event) => { setHourEarth(event.target.value) }}>
            {earthBranchOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className={styles['grid-cell']}>
          <select className={styles['comboBox']} id="cell-6" value={dayEarth} onChange={(event) => { setDayEarth(event.target.value) }}>
            {earthBranchOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className={styles['grid-cell']}>
          <select className={styles['comboBox']} id="cell-7" value={monthEarth} onChange={(event) => { setMonthEarth(event.target.value) }}>
            {earthBranchOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className={styles['grid-cell']}>
          <select className={styles['comboBox']} id="cell-8" value={yearEarth} onChange={(event) => { setYearEarth(event.target.value) }}>
            {earthBranchOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className={styles['grid-container']}>
        <div className={styles['grid-cell']}>
          查詢結果:
        </div>
        <div className={styles['grid-cell']}>
          <input type="datetime-local"
            className={styles.inputDatetime}
            disabled={birthday.length === 0} value={birthday}
            onChange={(e) => { setBirthday(e.target.value) }} />
        </div>

        <div className={styles['grid-cell']}>
        </div>
        <div className={styles['grid-cell']}>
          <div className={styles.genderPadding}>
            <GenderSelection disabled={birthday.length === 0} setIsMale={props.setIsMale} isMale={props.isMale} />
          </div>
        </div>
      </div>
      <div>
        {birthdays.length > 0 && <div className={styles.scrollableList}>
        <div>請選擇以下其中一個日期時間:</div>
          {birthdays.map(dateTime => (
            <div key={dateTime} className={styles.dateTimeOption}>
               
              <input
                type="radio"
                id={dateTime}
                name="birthday"
                value={dateTime}
                onChange={() => {
                  setBirthday(dateTime);
                  setBirthdays([])
                }}
              />
              <label htmlFor={dateTime}>{dateTime.replace('T', ' ').substring(0, dateTime.lastIndexOf(':'))}</label>
            </div>
          ))}
        </div>
        }
      </div>
      <div className={styles.queryButton}>
        <button disabled={birthday.length === 0} onClick={() => { confirmBirthday(birthday) }} >查詢</button>
      </div>


      {/* {showPopup && <div className={`${styles.popupOverlay} ${showPopup ? styles.show : ''}`}>
            <div className={styles.popupContent}>
              <h3 className={styles.popupTitle}>請選擇以下其中一個日期時間:</h3>
              {birthdays.map((dateTime, index) => (
                <div 
                  key={index}
                  className={styles.dateTimeOption}
                  onClick={() => {
                    setBirthday(dateTime);
                    setShowPopup(false);
                    setBirthdays([]);
                  }}
                >
                  {dateTime}
                </div>
              ))}
              <div className={styles.dateTimeOption} onClick={() => {
                setShowPopup(false);
                setBirthdays([]);
              }}>
                取消
              </div>
            </div>
        </div>
      } */}
    </>
  );
}

