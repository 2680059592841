import RelationTable from "../../component/Horosope/RelationTable"
import Tabs from 'react-responsive-tabs'; //tabpage
import 'react-responsive-tabs/styles.css';//tabpage

export default function Hint(props){


    const tabDefinition = [
        { name: '十神關係', tabContent: () => getRelationTableUI() },
   
      ];

    const getRelationTableUI = () => {
        return (
            props.eightLettersResult &&  props.eightLettersResult.letterAddress && props.eightLettersResult.letterAddress.string
                && props.eightLettersResult.letterAddress.string.length > 5 ?
                <RelationTable isMale={props.isMale} dayStemLetter={props.eightLettersResult.letterAddress.string[4]} /> : null
        )
    }

    
  function getTabs() {
    return tabDefinition.map((definition, index) => ({
      title: definition.name,
      getContent: () => definition.tabContent(),
      /* Optional parameters */
      key: index,
      tabClassName: 'tab',
      panelClassName: 'panel',
      transform: false
    }));
  }

    return (
        <>
        {/* tab page for date input */}
        <Tabs items={getTabs()} transform={false} />
      </>
    )
}