import React, { useEffect, useState } from "react";
import styles from './index.module.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FiveElementScore from "../../../component/Horosope/FiveElementScore";

export default function BookReference(props) {

    const [rows, setRows] = useState([])

    const parseMonthBranchAnalysis = (horosopeResult) => {
        let result = [];
        if (horosopeResult && horosopeResult.monthBranchAnalysis) {
            const analysis = horosopeResult.monthBranchAnalysis

            const monthText = horosopeResult.letterAddress.string.trim()[4] + "生於" + analysis.month + analysis.seasonType
                + "," + (analysis.isMatched ? "得令" : "不得令") + "。" +
                `${horosopeResult.letterAddress.string.trim()[4]}${analysis.dayStemStatus}於${horosopeResult.letterAddress.string.trim()[3]}`
            result.push(createData("月令分析", monthText))
            result.push(createData("滴天髓", "宜用" + analysis.suggestion))
        }
        return result;
    }

    const createData = (name, value) => {
        return { name, value };
    };





    useEffect(() => {
        //分析月令
        const result = []
        result.push(createData("五行分數", <FiveElementScore inputDate={props.inputDate} isMale={props.isMale}
            LoadingSpinner={props.LoadingSpinner} />))
        result.push(...parseMonthBranchAnalysis(props.horosopeResult))
        setRows(result)

    }, [props.horosopeResult])

    return (
        <TableContainer component={Paper} className={styles.tableContainer}>
            <Table className={styles.table}>
                <TableHead className={styles.tableHead}>
                    {/* <TableRow>
                        <TableCell className={styles.tableHeadCell}>Name</TableCell>
                        <TableCell className={styles.tableHeadCell}>Description</TableCell>
                    </TableRow> */}
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow className={styles.tableRow} key={row.name + index}>
                            <TableCell
                                className={`${styles.tableBodyCell} ${styles.firstTableCell}`}
                                sx={{ fontWeight: 'bold' }}
                            >
                                {row.name}
                            </TableCell>
                            <TableCell className={styles.tableBodyCell}>{row.value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>



    )
}