import React, { useEffect, useRef, useState } from "react";
import { get } from '../../axios'
import styles from "./index.module.css"
import Letter from "../Letter";
import { compareBirthday, getMaxEvenNumber, isSeatExtra } from "../../Helper/DateTimeHelper";
import { globalData } from "../../Helper/global";
import { getLetterAddressObj, isAnyIndexEarthBranch } from "../../Helper/AddressObj";
import {
  parseLetterAddressAllDate, getDeviatedLetters,
  getEarthBranchIndexList, getMainText,
  getSkyStemIndexList

} from "../../Helper/AddressObj"
import LetterDeviated from "../LetterDeviated";
import Xarrow, { useXarrow, Xwrapper } from 'react-xarrows';
import { getExtraInfoMask } from "../VToolbar/helper"
import MsonSelector from "../MsonSelector";
import useScreenSizeCheck from "../../hook/useScreenSizeCheck";

export const arrowStyles = {

  "拱": { "color": "#99CC99", "strokeWidth": 1, extraInfoMask: 11 },
  "組合": { "color": "#8390FF", "strokeWidth": 1, extraInfoMask: 10 },
  "假合化": { "color": "#D97ED7", "strokeWidth": 1, extraInfoMask: 12 },
  "真合化": { "color": "#FF5679", "strokeWidth": 1, extraInfoMask: 12 },
  "遇龍則化": { "color": "#FF5679", "strokeWidth": 1, extraInfoMask: 12 },
  "木局拱": { "color": "#FFC178", "strokeWidth": 1, extraInfoMask: 11 },
  "火局拱": { "color": "#FFC178", "strokeWidth": 1, extraInfoMask: 11 },
  "土局拱": { "color": "#FFC178", "strokeWidth": 1, extraInfoMask: 11 },
  "金局拱": { "color": "#FFC178", "strokeWidth": 1, extraInfoMask: 11 },
  "水局拱": { "color": "#FFC178", "strokeWidth": 1, extraInfoMask: 11 },

}


export default function EightLetters(props) {

  const arrArrowOffset = [0, 4, -4, 8, -8, 12, -12, 16, -16, 20, -20, 24, -24]  //offset to avoid arrow overlap at horizontal direction
  const maxFortuneDayLetterAddress = 36
  const [deviatedLetters, setDeviatedLetters] = useState(undefined);  //data of deviated letters
  const [parties, setParties] = useState(undefined);  //data of parties
  const [seats, setSeats] = useState(undefined);  //data of seats e.g. {0:40,1:41} mean seat 0 has letter 40 , seat 1 has letter 41 
  const isSmallScreen = useScreenSizeCheck();

  /**
      * Object structure for mson selector:
      * {
      * msonId:{status:true,sentence:label.sentence}
      * }
      */


  /**
   * Get 原局 from server
   */
  // const getBirthDateFromServer = () => {
  //   props.setEightLettersResult(null) //clear result before making request
  //   if (props.inputDate) {
  //     get(`api/birthday/chinesedate/${props.inputDate}/ismale/${props.isMale}?t=${Date.now()}`).then(res => {
  //       if (res.data && res.data.success) {
  //         if (compareBirthday(res.data.data.birthday, globalData.inputDate)) {
  //           if (res.data.data && res.data.data.letterAddress && res.data.data.letterAddress.string
  //             && res.data.data.letterAddress.string.length > 5) {
  //             props.setSelectedLetter(res.data.data.letterAddress.string[4])  //default select 日元
  //           }
  //           // setTimeout(() => {
  //           //   setIsDLetterReady(true)

  //           // }, 1500); //add time buffer on setting props
  //           props.setEightLettersResult(res.data.data)
  //         } else {
  //           console.log("EightLetters birthday " + res.data.data.birthday + " is not up to date.")
  //         }
  //       } else {
  //         console.log("EightLetters birthday is null.")
  //       }
  //     })
  //   }
  // }

  //when input date changes, get birthdate from server
  useEffect(() => {
    //reset 
    setDeviatedLetters(undefined)
    setParties(undefined)
    setSeats(undefined)

    //  getBirthDateFromServer()
  }, [props.inputDate, props.isMale])


  /**
   * When yearfortune is ready, setDeviatedLetters for 原局
   */
  useEffect(() => {
    if (props.analysisResult) {
      setDeviatedLetterFromServer(props.analysisResult)
    }
  }, [props.analysisResult])

  /**
   * Combined effect to get analysis for different time periods:
   * - Original chart (原局)
   * - 10-year fortune (大運)
   * - Yearly fortune (流年)
   * - Monthly fortune (流月)
   */
  useEffect(() => {
    // Reset data when conditions aren't met
    if (!props.year10Ready || !props.shouldDrawDLetter) {
      return;
    }

    let year10 = 0;
    let year = 0;
    let month = 0;

    // Handle monthly fortune
    if (props.selectedMonth) {
      const yearMonthDay = props.selectedMonth.split('-');
      if (yearMonthDay?.length === 3) {
        year10 = props.selectedYear10;
        year = yearMonthDay[0];
        month = yearMonthDay[1];
      }
    }
    // Handle yearly fortune
    else if (props.selectedYear) {
      year10 = props.selectedYear10;
      year = props.selectedYear;
    }
    // Handle 10-year fortune
    else if (props.selectedYear10 !== 0) {
      year10 = props.selectedYear10;
    }

    // Get analysis data
    props.getAnalysisOriginal(year10, year, month).then(data => {
      if (data) {
        props.setAnalysisResult(data);
      }
    });

  }, [
    props.year10Ready,
    props.shouldDrawDLetter,
    props.selectedYear10,
    props.selectedYear,
    props.selectedMonth
  ]);


  /**
   * When seats is ready, setDeviatedLetters to true
   * Show arrow legend and list of combination
   */
  useEffect(() => {
    if (seats) {
      props.setIsDLetterReady(true)
    }
  }, [seats])

  /**
   * set server side data into deviatedLetters
   * @param {*} data res.data.data from server
   */
  const setDeviatedLetterFromServer = (data) => {
    if (props.shouldDrawDLetter) {
       const allCombination = parseLetterAddressAllDate(data);
       props.setLetterAddressAllDateObj(allCombination)  //for setting to legend

      if (allCombination.letterAddressAllDate) {
        let dateArr = Object.values(allCombination.letterAddressAllDate)
        if (dateArr.length > 0) {

          //get [0] because only 1 record should be return, as we ask for specific date
          let details = dateArr[0].details
          if (details && details.length > 0) {
            let result = getDeviatedLetters(details)
            setDeviatedLetters(result["deviatedLetters"])
            setParties(result["parties"])
            calcSeats(result["deviatedLetters"])
          } else {
            console.log("detailsArr is undefined or empty")
          }

        }
      }
    }
  }



  /**
   * Calculate seats for each deviated letter
   * Parameters sample:
   * {
    "40": {
      id: "40",
      seats: [4,2,],
      action: "組合",
      new: "午",
      detail: [
        "40|組|午|火|組合|丁4|己2|",
        "41|拱|巳|火|拱|辰1|午40|",
        "|會||火|半會火局|巳3;41|午40|",
        "|會||水|半會水局|亥5|子7|",
        "|會||水|半合水局||子7|辰1",
        "42|合|戊|土|合假化|甲0|己2|",
      ],
      arrow: {"2": "己", "4": "丁",},
    },
    "41": {
      id: "41",
      seats: [ 1,40,],
      action: "拱",
      new: "巳",
      detail: [
        "40|組|午|火|組合|丁4|己2|","41|拱|巳|火|拱|辰1|午40|",
        "|會||火|半會火局|巳3;41|午40|","|會||水|半會水局|亥5|子7|",
        "|會||水|半合水局||子7|辰1","42|合|戊|土|合假化|甲0|己2|",
      ],
      arrow: {"1": "辰","40": "午",},
    }
  }
   * @param {*} dLetters 
   */
  const calcSeats = (dLetters) => {
    if (dLetters && Object.keys(dLetters).length > 0) {
      let dLetterObjArr = Object.values(dLetters)
      //sort by length of seats for priority
      dLetterObjArr.sort((a, b) => a.seats.length - b.seats.length);

      let assignedSeats = {}; //key: seat no, the address of creator close to deviated letter, value: the address of deviated letter
      let allSeats = [];  //all the seat no we assigned so far

      for (let obj of dLetterObjArr) { //for each deviated letter
        for (let seat of obj.seats) { //get which letters created devicated letter
          if (!allSeats.includes(seat) && seat <= maxFortuneDayLetterAddress) { //check whether the number of the creator not yet occupied
            //for seat > 36, assign seat later and append to the end of seat list
            assignedSeats[seat] = obj.id; //occupy it. The deviated letter should sit close to creator
            allSeats.push(seat);  //add to a list of all occupied seats
            break;
          }
        }
      }

      // If no preferred seat is available, find an empty one according to creator is sky or earth
      for (let dLetter of dLetterObjArr) {
        //check all deviated letters
        if (!Object.values(assignedSeats).includes(dLetter.id)) { //if deviated letter not yet get a seat
          if (dLetters[dLetter.id].seats && dLetters[dLetter.id].seats.length > 0) {
            //get whether any of the creator come from earth branch 
            const isCreatorEarth = isAnyIndexEarthBranch(dLetters[dLetter.id].seats[0])

            //get next available seat of sky or earth
            let arrAllSeatNo = []
            if (isCreatorEarth) {
              arrAllSeatNo = getEarthBranchIndexList()
            } else {
              arrAllSeatNo = getSkyStemIndexList()
            }

            let isLetterGotSeat = false;  //once deviated letter got seat, use this to skip forEach loop
            arrAllSeatNo.forEach(seatNo => {  //loop through all seat for sky or earth
              if (!allSeats.includes(seatNo) && !isLetterGotSeat) { //first the first seat not yet occupied
                assignedSeats[seatNo] = dLetter.id;
                allSeats.push(seatNo);
                isLetterGotSeat = true;
              }
            });

            if (!isLetterGotSeat) {  //if no seat for this letter, add more seat 
              if (isCreatorEarth) {
                const maxSeatNo = getMaxEvenNumber(allSeats) + 2 //+2 because the next max seat also even number
                assignedSeats[maxSeatNo] = dLetter.id;
                allSeats.push(maxSeatNo);
              }
            }
          } else {
            console.error("EightLetters: deviated letter has no seats")
          }
        }
      }
      setSeats(assignedSeats)
    } else {
      // console.error("EightLetters: dLetters is null")
    }
  }





  /**
   * Show text of whether 10 year fortune is entered or not
   * @testcase 0歲入運 https://calc.adacs.com.hk/inputDate/1922-05-05T01:01/ismale/true
   *  @param {*} isEnteredFortune10 
   * @returns 
   */
  const get10YearFortuneRangeText = (isEnteredFortune10) => {
    if (props.selectedYear10 !== 0 && props.selected10YearRange) {
      if (props.selectedYear10IndexNumber === 0) {
        return <>未入運</>
      } else {
        return <>大運<br />{props.selected10YearRange.replace(/-(..)/, '-')}</>

        // return "大運" + props.selected10YearRange.substring(2).replace(/-(..)/, '-')
      }
    }
  }


  /**
   *  Draw deviated letters on sky or earth
   * @param {*} type 0 is skystem; 1 is earthbranch 
   */
  const drawDeviatedLetters = (type) => {
    if (seats) {
      let arrIndex = []
      let isSkySteamCompare = false
      if (type === 0) {
        arrIndex = getSkyStemIndexList()
        isSkySteamCompare = true
      } else {
        arrIndex = getEarthBranchIndexList()
      }

      let arrExtraSeatsShouldBeDraw = []

      //get total count on deviated letter to be drawn in sky or earth. 
      //If there is not many deviated letters , don't need to draw empty div
      //check how many seat number included in arrIndex
      const totalDeviatedLetter = Object.keys(seats).filter(seatNo => {
        let intSeatNo = parseInt(seatNo)
        let isExtraSeat = isSeatExtra(intSeatNo, type)
        let result = arrIndex.includes(intSeatNo) || isExtraSeat
        if (result && isExtraSeat) {
          arrExtraSeatsShouldBeDraw.push(intSeatNo)
        }
        return result
      }).length


      let jsxDLetter = []
      let arrDeviatedLetterAddress = [] //The address of deviated letter
      for (let num of arrIndex.concat(arrExtraSeatsShouldBeDraw)) {

        const msonIndex = seats[num]  //eg. 40, 41 which is larger than address index

        if (msonIndex === undefined) {
          //case: no letter on this seat
          if (totalDeviatedLetter > arrDeviatedLetterAddress.length) {  //if there are more deviated letters to draw, add an empty div to keep space
            jsxDLetter.push(<div className={styles.letterGridBorder} key={"letterGridBorderEmpty" + num}></div>)
          }
        } else {
          arrDeviatedLetterAddress.push(msonIndex)
          const mainText = getMainText(props.selectedLetter, deviatedLetters[msonIndex].new)
          // intAddedDeviatedLetters++ //count one more deviated letter added

          jsxDLetter.push(
            <div id={"L" + msonIndex} key={"L" + msonIndex}
              style={{
                opacity: (props.msonIdPartyShowList && props.msonIdPartyShowList[msonIndex] &&
                  props.msonIdPartyShowList[msonIndex].status === false) ? 0.4 : 1,
                display: (props.msonIdPartyShowList && props.msonIdPartyShowList[msonIndex] &&
                  Object.values(props.msonIdPartyShowList).every(item => item.status === false) ? "none" : "block")
              }}
            >
              <LetterDeviated key={"LetterDeviated" + num}
                index={msonIndex} mainText={mainText} border="true"
                selectedLetterIndex={props.selectedLetterIndex} setSelectedLetterIndex={props.setSelectedLetterIndex}
                selectedLetter={props.selectedLetter} setSelectedLetter={props.setSelectedLetter} extraInfoMask={props.extraInfoMask}
              />
            </div>
          )
        }
      }
      //setIsDLetterReady(true)
      return <><div className={styles.gridContainerSingleRow} key="deviatedLetterSky"> {jsxDLetter} </div>{drawArrow(arrDeviatedLetterAddress, isSkySteamCompare)}</>
    }
  }






  /**
   * Seat 是天干的嗎?
   * @param {*} seatNo 
   * @returns bool
   */
  const isSkyStemSeat = (seatNo) => {
    if (seatNo > maxFortuneDayLetterAddress) {
      return (seatNo % 2 === 1) //odd number is sky
    } else {
      const arrSkyAddress = getSkyStemIndexList()
      return arrSkyAddress.includes(parseInt(seatNo))
    }
  }


  const drawArrowStyle = (dletterAddress, creatorAddress, isSkyStem, lastOffsetIndex) => {
    let result = {
      path: "smooth",
      startAnchor: { position: "auto", offset: { x: 0 } },
      endAnchor: { position: "auto", offset: { x: 0 } },
      lastOffsetIndex: lastOffsetIndex
    }

    let seatNoDLetter = Object.keys(seats).find(seatNo => seats[seatNo] === dletterAddress)  //seat no. of dletter

    //If creator has seat no., it is d letter. Otherwise, creatorAddress is already seat address of regular letter
    const seatNoCreator = Object.keys(seats).find(seatNo => seats[seatNo] === creatorAddress) || creatorAddress

    const isStartSky = isSkyStemSeat(seatNoCreator)
    const isSameSkyOrEarth = (isStartSky == isSkyStemSeat(seatNoDLetter)) // If both true or both false, result is true

    if (creatorAddress <= maxFortuneDayLetterAddress && isSameSkyOrEarth) {  //樓上的字
      if (isSkyStem) {
        result["startAnchor"]["position"] = "top"
        result["endAnchor"]["position"] = "bottom"
      } else {
        result["startAnchor"]["position"] = "bottom"
        result["endAnchor"]["position"] = "top"
      }
    } else if (isSameSkyOrEarth || creatorAddress > maxFortuneDayLetterAddress) {  //creator letter is also deviated letter


      //if all arrow offsets are used, use the first one
      const offset = (arrArrowOffset[lastOffsetIndex] || arrArrowOffset[0])

      if (isSameSkyOrEarth) {
        result["startAnchor"]["position"] = "middle"
        result["endAnchor"]["position"] = "middle"
        result["startAnchor"]["offset"] = { y: offset }
        result["endAnchor"]["offset"] = { y: offset }
        result["path"] = "grid"
        result["lastOffsetIndex"] = lastOffsetIndex + 1
      }
    }
    return result
  }

  const drawArrow = (arrDeviatedLetterAddress, isSkyStem) => {

    let result = []

    if (arrDeviatedLetterAddress) {

      let lastOffsetIndex = 0 //remember last offset index to even distribute the arrow in the same sky or earth

      arrDeviatedLetterAddress.forEach(dletterAddress => {
        const deviatedLetter = deviatedLetters[dletterAddress] //get the deviated letter from state by address
        if (deviatedLetter && deviatedLetter.arrow) {
          Object.keys(deviatedLetter.arrow).forEach(creatorAddress => {

            const arrStyle = arrowStyles[deviatedLetter.action]

            const arrowStyle = drawArrowStyle(dletterAddress, creatorAddress, isSkyStem, lastOffsetIndex)
            lastOffsetIndex = arrowStyle["lastOffsetIndex"]

            const start = "L" + creatorAddress
            const end = "L" + dletterAddress

            result.push(<Xarrow
              key={"Xarrow" + start + end + deviatedLetter.action}
              style={{ zIndex: 10 }}
              start={start} // the id of the starting element
              end={end} // the id of the ending element
              color={props.msonIdPartyShowList && props.msonIdPartyShowList[dletterAddress] &&
                props.msonIdPartyShowList[dletterAddress].status === false ? "transparent" : arrStyle.color} // color of the arrow
              // color={getExtraInfoMask(props.extraInfoMask, arrStyle.extraInfoMask) ? arrStyle.color : "transparent" } // color of the arrow
              strokeWidth={arrStyle.strokeWidth} // thickness of the line
              headSize={10} // the size of the arrow head
              tailSize={5} // the size of the arrow head
              tailShape='circle'
              showTail
              showHead // show an arrow at the end
              startAnchor={arrowStyle["startAnchor"]}
              endAnchor={arrowStyle["endAnchor"]}
              path={arrowStyle["path"]}
            // animateDrawing={true}
            // dashness={{ strokeLen: 10, nonStrokeLen: 15, animation: 5 }}

            />)
          })
        }
      });
    }

    return result

  }

  const drawLetterGrid = () => {

    if (props.eightLettersResult && props.eightLettersResult.letterAddress) {
      let arrletterAddressObj = []

      //convert letterAddress to input of <Letter>
      const letterAddressString = props.eightLettersResult.letterAddress.string
      let letterAddressObj = getLetterAddressObj(letterAddressString)


      return (

        <div className={styles.containerOfLegendLetter}>

          <div className={styles.content}>
            {/* <div style={{width:"500px",overflow:"auto"}}> */}
            <div>
              {drawDeviatedLetters(0)}
            </div>

            <div className={styles.gridContainer4Columns}>

              <div className={styles['grid-itemTitle']}>原局</div>
              {/* selectedYear10 is year of 10. E.g. 2024. If 2024 is not yet "入運", 2024 is not contained in the key index yearfortune100Result.tenYearFortune */}
              <div className={styles['grid-itemTitle']}>{get10YearFortuneRangeText()}</div>
              <div className={styles['grid-itemTitle']}>{props.selectedYear != 0 && "流年"}<br />{props.selectedYear == 0 ? "" : props.selectedYear}</div>
              <div className={styles['grid-itemTitle']}>{props.selectedMonth != 0 && "流月"}{props.selectedMonth == 0 ? "" : props.selectedMonth}</div>

              {/* 原局 */}
              <div className={styles['grid-item']}>
                <div className={styles.gridContainer4Columns}>
                  <div id={"L6"}>
                    <Letter index={6} className={styles['grid-item']} mainText={letterAddressObj[6]} border="true"
                      selectedLetterIndex={props.selectedLetterIndex} setSelectedLetterIndex={props.setSelectedLetterIndex}
                      selectedLetter={props.selectedLetter} setSelectedLetter={props.setSelectedLetter} extraInfoMask={props.extraInfoMask}
                      halfHeight={true}
                    />
                  </div>

                  <div id={"L4"}>
                    <Letter index={4} className={styles['grid-item']} mainText={letterAddressObj[4]} border="true"
                      selectedLetterIndex={props.selectedLetterIndex} setSelectedLetterIndex={props.setSelectedLetterIndex}
                      selectedLetter={props.selectedLetter} setSelectedLetter={props.setSelectedLetter} extraInfoMask={props.extraInfoMask}
                      halfHeight={true} />
                  </div>
                  <div id={"L2"}>

                    <Letter id={"L2"} index={2} className={styles['grid-item']} mainText={letterAddressObj[2]} border="true"
                      selectedLetterIndex={props.selectedLetterIndex} setSelectedLetterIndex={props.setSelectedLetterIndex}
                      selectedLetter={props.selectedLetter} setSelectedLetter={props.setSelectedLetter} extraInfoMask={props.extraInfoMask}
                      halfHeight={true} />
                  </div>

                  <div id={"L0"}>

                    <Letter id={"L0"} index={0} className={styles['grid-item']} mainText={letterAddressObj[0]} border="true"
                      selectedLetterIndex={props.selectedLetterIndex} setSelectedLetterIndex={props.setSelectedLetterIndex}
                      selectedLetter={props.selectedLetter} setSelectedLetter={props.setSelectedLetter} extraInfoMask={props.extraInfoMask}
                      halfHeight={true} />
                  </div>

                  <div id={"L7"}>

                    <Letter id={"L7"} index={7} className={styles['grid-item']} mainText={letterAddressObj[7]} border="true"
                      selectedLetterIndex={props.selectedLetterIndex} setSelectedLetterIndex={props.setSelectedLetterIndex}
                      selectedLetter={props.selectedLetter} setSelectedLetter={props.setSelectedLetter} extraInfoMask={props.extraInfoMask} />
                  </div>

                  <div id={"L5"}>

                    <Letter id={"L5"} index={5} className={styles['grid-item']} mainText={letterAddressObj[5]} border="true"
                      selectedLetterIndex={props.selectedLetterIndex} setSelectedLetterIndex={props.setSelectedLetterIndex}
                      selectedLetter={props.selectedLetter} setSelectedLetter={props.setSelectedLetter} extraInfoMask={props.extraInfoMask} />
                  </div>
                  <div id={"L3"}>

                    <Letter id={"L3"} index={3} className={styles['grid-item']} mainText={letterAddressObj[3]} border="true"
                      selectedLetterIndex={props.selectedLetterIndex} setSelectedLetterIndex={props.setSelectedLetterIndex}
                      selectedLetter={props.selectedLetter} setSelectedLetter={props.setSelectedLetter} extraInfoMask={props.extraInfoMask} />
                  </div>

                  <div id={"L1"}>
                    <Letter id={"L1"} index={1} className={styles['grid-item']} mainText={letterAddressObj[1]} border="true"
                      selectedLetterIndex={props.selectedLetterIndex} setSelectedLetterIndex={props.setSelectedLetterIndex}
                      selectedLetter={props.selectedLetter} setSelectedLetter={props.setSelectedLetter} extraInfoMask={props.extraInfoMask} />
                  </div>



                </div>
              </div>

              {/* 大運開始 */}
              <div className={styles.gridContainer1Columns}>
                {
                  //check 是否巳入運, 未入運改為半透
                  props.yearfortune100Result && props.selectedYear10 != 0 && (props.yearfortune100Result.tenYearFortune.hasOwnProperty(props.selectedYear10) ?
                    <>
                      <div id={"L20"} >
                        <Letter index={20} className={styles['grid-item']}
                          mainText={getMainText(props.selectedLetter, props.yearfortune100Result.tenYearFortune[props.selectedYear10].yearPillar[0])}
                          border="true"
                          selectedLetterIndex={props.selectedLetterIndex} setSelectedLetterIndex={props.setSelectedLetterIndex}
                          selectedLetter={props.selectedLetter} setSelectedLetter={props.setSelectedLetter} extraInfoMask={props.extraInfoMask}
                          halfHeight={true} />
                      </div>



                      <div id={"L21"} >
                        <Letter index={21} className={styles['grid-item']}
                          mainText={getMainText(props.selectedLetter, props.yearfortune100Result.tenYearFortune[props.selectedYear10].yearPillar[1])}
                          border="true"
                          selectedLetterIndex={props.selectedLetterIndex} setSelectedLetterIndex={props.setSelectedLetterIndex}
                          selectedLetter={props.selectedLetter} setSelectedLetter={props.setSelectedLetter} extraInfoMask={props.extraInfoMask} />
                      </div>
                    </> : <>
                      <div id={"L20"} style={props.selectedYear10IndexNumber == 0 ? { opacity: '0.3' } : {}}>
                        <Letter index={20} className={styles['grid-item']}
                          mainText={getMainText(props.selectedLetter, props.yearfortune100Result.tenYearFortune[0].yearPillar[0])}
                          border="true"
                          selectedLetterIndex={props.selectedLetterIndex} setSelectedLetterIndex={props.setSelectedLetterIndex}
                          selectedLetter={props.selectedLetter} setSelectedLetter={props.setSelectedLetter} extraInfoMask={props.extraInfoMask}
                          halfHeight={true} />
                      </div>

                      <div id={"L21"} style={props.selectedYear10IndexNumber == 0 ? { opacity: '0.3' } : {}}>
                        <Letter index={21} className={styles['grid-item']}
                          mainText={getMainText(props.selectedLetter, props.yearfortune100Result.tenYearFortune[0].yearPillar[1])}
                          border="true"
                          selectedLetterIndex={props.selectedLetterIndex} setSelectedLetterIndex={props.setSelectedLetterIndex}
                          selectedLetter={props.selectedLetter} setSelectedLetter={props.setSelectedLetter} extraInfoMask={props.extraInfoMask} />
                      </div>
                    </>
                  )
                }

              </div>
              {/* 大運結束 */}

              {/* 流年開始 */}
              {
                props.yearfortune100Result && props.selectedYear !== 0 &&
                <div className={styles.gridContainer1Columns}>
                  <div id={"L25"}>
                    <Letter index={25} className={styles['grid-item']}
                      mainText={getMainText(props.selectedLetter, props.yearfortune100Result.yearFortune[props.selectedYear].yearPillar[0])}
                      border="true"
                      selectedLetterIndex={props.selectedLetterIndex} setSelectedLetterIndex={props.setSelectedLetterIndex}
                      selectedLetter={props.selectedLetter} setSelectedLetter={props.setSelectedLetter} extraInfoMask={props.extraInfoMask}
                      halfHeight={true} />
                  </div>

                  <div id={"L26"}>
                    <Letter index={26} className={styles['grid-item']}
                      mainText={getMainText(props.selectedLetter, props.yearfortune100Result.yearFortune[props.selectedYear].yearPillar[1])}
                      border="true"
                      selectedLetterIndex={props.selectedLetterIndex} setSelectedLetterIndex={props.setSelectedLetterIndex}
                      selectedLetter={props.selectedLetter} setSelectedLetter={props.setSelectedLetter} extraInfoMask={props.extraInfoMask} />
                  </div>
                </div>
              }
              {/* 流年結束 */}

              {/* 流月開始 */}
              {
                props.yearfortune100Result && props.selectedMonth !== 0 &&
                <div className={styles.gridContainer1Columns}>
                  <div id={"L30"}>
                    <Letter index={30} className={styles['grid-item']}
                      mainText={getMainText(props.selectedLetter,
                        props.yearfortune100Result.monthFortune[new Date(props.selectedMonth.split(" ")[0]).toISOString().slice(0, 19)].monthPillar[0]
                      )}

                      border="true"
                      selectedLetterIndex={props.selectedLetterIndex} setSelectedLetterIndex={props.setSelectedLetterIndex}
                      selectedLetter={props.selectedLetter} setSelectedLetter={props.setSelectedLetter} extraInfoMask={props.extraInfoMask}
                      halfHeight={true} />
                  </div>

                  <div id={"L31"}>
                    <Letter index={31} className={styles['grid-item']}
                      mainText={getMainText(props.selectedLetter,
                        props.yearfortune100Result.monthFortune[new Date(props.selectedMonth.split(" ")[0]).toISOString().slice(0, 19)].monthPillar[1]
                      )}
                      border="true"
                      selectedLetterIndex={props.selectedLetterIndex} setSelectedLetterIndex={props.setSelectedLetterIndex}
                      selectedLetter={props.selectedLetter} setSelectedLetter={props.setSelectedLetter} extraInfoMask={props.extraInfoMask} />
                  </div>
                </div>
              }
              {/* 流月結束 */}



            </div>
            <div>
              {drawDeviatedLetters(1)}
            </div>
          </div>
          {/* do not show on mobile. No space
          show when downloaded analysis per year month
          show when addressSchema is downloaded */}
          {/* {!isSmallScreen && props.isDLetterReady && props.addressSchema && <div className={styles.legend}>
            <MsonSelector letterAddressAllDateObj={props.letterAddressAllDateObj} addressSchema={props.addressSchema}
              msonIdPartyShowList={props.msonIdPartyShowList} setMsonIdPartyShowList={props.setMsonIdPartyShowList}
              shouldWrapWithDraggable={true} showMinimizeButton={true}
            />
          </div>} */}


        </div>
      )
    } else {
      return (<div></div>)
    }
  }




  return (

    <div>
      {drawLetterGrid()}


    </div>
  );



} 