import React, { useEffect, useState } from 'react';
import styles from "./index.module.css";
import {
    parseDetailsOfLetterAddressToArray,
} from "../../Helper/AddressObj";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Draggable from "react-draggable";
import PropTypes from 'prop-types';
import _ from 'lodash';
import Tabs from 'react-responsive-tabs'; //tabpage
import 'react-responsive-tabs/styles.css';//tabpage
import Badge from '@mui/material/Badge';

export default function MsonSelectorTab(props) {
    const [classOfLegend, setClassOfLegend] = useState(styles.legendContent);
    const [currentUiType, setCurrentUiType] = useState(["合"]); //default to show "合"

    // Add a ref to track initial render. For the first time, hide the mson list that doesn't fit the uiType
    const isInitialMount = React.useRef(true);

    useEffect(() => {
        if (props.letterAddressAllDateObj) {
            parseCombinationToSwitch(props.letterAddressAllDateObj);
        }
    }, [props.letterAddressAllDateObj]);

    //hide mson list that doesn't fit the uiType
    useEffect(() => {
        if (currentUiType.length > 0 
            && props.msonIdPartyShowList && Object.keys(props.msonIdPartyShowList).length > 0) {
            hideUnrelatedMsonList(currentUiType)
        }
    }, [currentUiType])

    // Add new useEffect to handle initial filtering after msonIdPartyShowList is set
    useEffect(() => {
        if (isInitialMount.current && props.msonIdPartyShowList && Object.keys(props.msonIdPartyShowList).length > 0) {
            hideUnrelatedMsonList(currentUiType); // Apply initial filtering for first tab
            isInitialMount.current = false;
        }
    }, [props.msonIdPartyShowList]);



    useEffect(() => {
        if (props.selectedYear10 || props.selectedYear || props.selectedMonth) {
            isInitialMount.current = true;
        }
    }, [props.selectedYear10, props.selectedYear, props.selectedMonth])


    //#region Tab page

    const tabDefinition = [
        { name: '合化', uiType: ["合"], tabContent: () => prepareMsonListInTab(["合"]) },
        { name: '組合', uiType: ["組"], tabContent: () => prepareMsonListInTab(["組"]) },
        { name: '拱', uiType: ["拱"], tabContent: () => prepareMsonListInTab(["拱"]) },
        { name: '刑沖破害', uiType: ["刑", "沖", "破", "害"], tabContent: () => prepareMsonListInTab(["刑", "沖", "破", "害"]) },
        { name: '合局會局', uiType: ["會"], tabContent: () => prepareMsonListInTab(["會"]) },
        { name: '其他', uiType: ["陽", "祿", "克"], tabContent: () => prepareMsonListInTab(["陽", "祿", "克"]) },
    ];

    /**
     * get the count of mson that fits the uiType
     * @param {*} uiType 
     * @returns count
     */
    const getCountForType = (uiType) => {
        if (!props.msonIdPartyShowList) return 0;
        const count = Object.values(props.msonIdPartyShowList).filter(item =>
            isMsonRowFitUIType(item.action, uiType)
        ).length
        return count
    };


    function getTabs() {
        return tabDefinition.map((definition, index) => ({
            title: (
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    {definition.name}
                    <Badge
                        badgeContent={getCountForType(definition.uiType)}
                        color="error"
                        sx={{
                            '& .MuiBadge-badge': {
                                fontSize: '10px',
                                height: '20px',
                                minWidth: '20px',
                                borderRadius: '10px',
                            }
                        }}
                    />
                </div>
            ),
            getContent: () => definition.tabContent(),
            key: index,
            tabClassName: 'tab',
            panelClassName: 'panel',
            transform: false,
            onClick: () => setCurrentUiType(definition.uiType)
        }));
    }

    //#endregion

    /**
     * change the switch state
     * Hide the letter by changing the status of MsonIdPartyShowList to  false
     * @param {*} msonId 
     */
    const onSwitchChange = (msonId) => {
        props.setMsonIdPartyShowList(prevMsonIdShowList => ({
            ...prevMsonIdShowList,
            [msonId]: { status: !prevMsonIdShowList[msonId].status, sentence: prevMsonIdShowList[msonId].sentence, action: prevMsonIdShowList[msonId].action }
        }));
    };


    /**
     * parse letterAddressAllDateObj into switch
     * @param {*} letterAddressAllDateObj - the source of data from server
     */
    const parseCombinationToSwitch = (letterAddressAllDateObj) => {
        if (letterAddressAllDateObj && letterAddressAllDateObj.letterAddressAllDate && Object.entries(letterAddressAllDateObj.letterAddressAllDate).length > 0) {
            const entries = Object.entries(letterAddressAllDateObj.letterAddressAllDate);
            let msonIdPartyShowListTemp = {};

            for (const [key, value] of entries) {
                if (value !== undefined) {
                    const details = value.details;
                    const arrDetailsObj = parseDetailsOfLetterAddressToArray(details, props.addressSchema);

                    arrDetailsObj.forEach((obj, i) => {
                        msonIdPartyShowListTemp[obj.msonId] = {
                            status: true,
                            sentence: obj.sentence,
                            action: obj.action
                        }; // set default to show arrow
                    });
                }
            }
            props.setMsonIdPartyShowList(msonIdPartyShowListTemp);    //create this status object for render <switch>
        }
    };

 

    /**
     * check if the msons sentence fits the uiType
     * @param {*} action The action of mson. e.g. props.msonIdPartyShowList[msonId].action
     * @param {*} uiType array of "合", "組", "拱", "會", "陽", "祿", "克"
     * @returns bool
     */
    const isMsonRowFitUIType = (action, uiTypeArray) => {
        if (action && uiTypeArray) {
            return uiTypeArray.some(uiType => action.includes(uiType))
        }
        return false;
    }

    /**
     * hide the mson list that doesn't fit the uiType
     * @param {*} uiType 
     */
    const hideUnrelatedMsonList = (uiType) => {
        const updatedList = {};
        Object.keys(props.msonIdPartyShowList).forEach((key) => {
            updatedList[key] = {
                ...props.msonIdPartyShowList[key],
                status: isMsonRowFitUIType(props.msonIdPartyShowList[key].action, uiType)
            };
        });
        props.setMsonIdPartyShowList(updatedList);
 
    };

    /**
     * Called by getTabs()
     * hide the mson list that doesn't fit the uiType and draw the mson list that fits the uiType
     * @param {*} uiType 
     */
    const prepareMsonListInTab = (uiType) => {
        if (props.msonIdPartyShowList && Object.keys(props.msonIdPartyShowList).length > 0) {
            return drawMsonListInTab(uiType)
        }
    }

    /**
     * draw the mson list that fits the uiType
     * @param {*} uiType 
     */
    const drawMsonListInTab = (uiType) => {
        return (
            <div>
                <div className={classOfLegend}>
                    {
                        props.msonIdPartyShowList && Object.keys(props.msonIdPartyShowList).length > 0 &&

                        Object.keys(props.msonIdPartyShowList).map((msonId, index) =>
                            <div key={"Div_FormControlLabel" + msonId} className={index % 2 === 0 ? styles.evenBg : styles.oddBg} >

                                {isMsonRowFitUIType(props.msonIdPartyShowList[msonId].action, uiType) && (isNaN(msonId) ? <div style={{ fontSize: '12px' }}>{props.msonIdPartyShowList[msonId].sentence}</div> :
                                    <FormControlLabel key={"FormControlLabel" + msonId}
                                        control={
                                            <Switch key={"Switch" + msonId}
                                                checked={props.msonIdPartyShowList[msonId].status}
                                                onChange={() => {
                                                    onSwitchChange(msonId)
                                                }}

                                            />
                                        }
                                        label={
                                            <Typography style={{ fontSize: '12px' }}>
                                                {props.msonIdPartyShowList[msonId].sentence}
                                            </Typography>
                                        }
                                    />
                                )}
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }

    return (
        <>
            {/* tab page for date input */}
            <Tabs
                items={getTabs()}
                transform={false}
                onChange={(index) => {
                    setCurrentUiType(tabDefinition[index].uiType);
                }}
            />
        </>
    )
}

MsonSelectorTab.propTypes = {
    letterAddressAllDateObj: PropTypes.object,
    addressSchema: PropTypes.array.isRequired,
    setMsonIdPartyShowList: PropTypes.func.isRequired,
    msonIdPartyShowList: PropTypes.object,
    shouldWrapWithDraggable: PropTypes.bool,
    showMinimizeButton: PropTypes.bool,
};