import React, { useEffect, useState } from "react";
import { get } from '../../../axios'
import styles from './index.module.css'

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import { compareBirthday } from "../../../Helper/DateTimeHelper";
import { globalData } from "../../../Helper/global";


export default function TaiSui(props) {


    const [colDefs, setColDefs] = useState([
        { field: "id", headerName: "年", width: '70px', sortable: false },
        {
            field: "content", headerName: "分析", flex: 1, autoHeight: true, cellStyle: { 'whiteSpace': 'normal' }, sortable: false,
            cellRenderer: function (params) {
            return <ul style={{ margin: '0px', padding: '0px 0px 0px 20px' }}> {params.value && typeof params.value.map === 'function'  && params.value.map((m, i) => { return <li key={params.data.id + i}>{m}</li> })}</ul>
            }
        }
    ]);

    colDefs.valueFormatter = params => {
        return params;
    }

    const [rowData, setRowData] = useState();

    useEffect(() => {
        if (props.inputDate != undefined && props.isMale != undefined && props.isAnalysisReady) {
        console.log("TaiSui: " + props.inputDate + " " + props.isMale + " " + props.isAnalysisReady)
            setRowData(null)    //clear result before making request
            get(`api/birthday/taisui/${props.inputDate}/ismale/${props.isMale}/usegod/${props.useGod1}${props.useGod2}?t=${Date.now()}`).then(res => {
                if (res.data.success) {
                    if (compareBirthday(res.data.data.birthday, globalData.inputDate)) {
                        let result = Object.keys(res.data.data).map(key => {
                            if(!isNaN(key)){    //check whether key is year e.g. 2024. It is to filter out {birthday: "1982-07-31T12:43:00}
                                return {
                                    "id": key,
                                    "content": res.data.data[key]
                                }
                            }
                        });
                        setRowData(result)
                    } else {
                        console.log("TaiSui birthday " + res.data.data.birthday + " is not up to date.")
                    }
                }
            })
        }
    }, [props.taiSuiQueryTime, props.inputDate, props.isAnalysisReady])


    const rowClicked = (e) => {
        //props.onTaiSuiYearSelected(e.data.id)
    }


    // Container: Defines the grid's theme & dimensions.
    return (

        rowData && (<div className={"ag-theme-quartz"}
            style={{ width: '100%', height: '300px' }}
        >
            <AgGridReact columnDefs={colDefs} rowData={rowData}
                onRowClicked={(e) => rowClicked(e)} />
        </div>)

    );

    // return (<div>
    // {/* {rowData && <AgGridReact rowData={rowData} columnDefs={colDefs} />} */}
    // <AgGridReact rowData={rowData} columnDefs={colDefs}  />
    // {/* <textarea className={props.className} readOnly value={parseTaiSui(reply)}>
    //     </textarea> */}
    // </div>)
}