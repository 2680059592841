import React, { useEffect } from 'react';
import BookReference from "../../component/Horosope/BookReference";

export default function UseGod(props) {


  //get horoscope analysis when inputDate or isMale is changed
  useEffect(() => {
    if (props.inputDate && props.isMale !== undefined) {
      props.getHoroscopeAnalysis()
    }
  }, [props.inputDate, props.isMale])


  return (
    <>
      {props.horosopeResult ? (
        <BookReference
          horosopeResult={props.horosopeResult}
          inputDate={props.inputDate} isMale={props.isMale}
          //loading spinner
          LoadingSpinner={props.LoadingSpinner}
        />
      ) : (<props.LoadingSpinner />)
      }

    </>
  );
}


