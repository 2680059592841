import './App.css';
import axios from 'axios';
import React, { useEffect, useState, useRef } from "react";
import Index from '../container/Index';
function App() {

  return (
    <>
      <div className="App">
        <Index />
      </div>
    </>
  );
}


export default App;
